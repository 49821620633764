<template>
    <div>
        <img src="../assets/img/404.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'OfficialWebsiteNotfound',

    data () {
        return {

        };
    },

    mounted () {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
div {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 600px;
        height: 400px;
    }
}
</style>